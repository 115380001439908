import React from "react";
import { useTheme } from "../../Context";
import "./Footer.css";

const Footer = (props) => {
  const { theme } = useTheme();
  return (
    <footer className={`footer footer--${theme.name}`}>
      <div className={`footer__top`}></div>
      <hr className={`footer__rule footer__rule--${theme.name}`} />
      <div className={`footer__bottom`}>
        <span className={`footer__copyright footer__copyright--${theme.name}`}>
          &copy;&nbsp;
          <a
            href='https://github.com/jacksonhardy'
            target='_blank'
            rel='noopener noreferrer'
            className={`footer__copyright footer__copyright--link footer__copyright--link--${theme.name}`}
          >
            Jackson Hardy
          </a>
          &nbsp; 2019
        </span>
        <p className={`footer__attribution footer__attribution--${theme.name}`}>
          All images are available here thanks to the wonderful people at &nbsp;
          <a
            href='https://unsplash.com'
            target='_blank'
            rel='noopener noreferrer'
            className={`footer__attribution footer__attribution--link footer__attribution--link--${theme.name}`}
          >
            Unsplash
          </a>
          &nbsp; and their fantastic API. If you want curated sets of images,
          are looking to search by keyword, tag, author, etc., or want to
          support the talented photographers behind every one of these photos;
          head over to{" "}
          <a
            href='https://unsplash.com'
            target='_blank'
            rel='noopener noreferrer'
            className={`footer__attribution footer__attribution--link footer__attribution--link--${theme.name}`}
          >
            Unsplash.com
          </a>
          . Also note that I have no affiliation with Unsplash, their API, or
          any of their images.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
