export const ACCESS_KEY_A =
  "48ab0296508fa15587c3a2f85327b34fbb1aec23ba6b17ff6731a2023e653f2b";
export const API_SECRET_A =
  "848bc74d718457b71409d37568da2c1a875bf37068825d09e8f09abb54fc1d12";

export const ACCESS_KEY_B = "RwoUUT0ca-uXj3rQHKLHR-BcMF4jrmXOuk7TZPFfEbY";
export const API_SECRET_B = "gRSXZ9e5r4mH_uZbP9p8uSIOEKpeB-VpmZORRgMinBs";

export const ACCESS_KEY_C =
  "373374215ac1507179ffff5cd70936c453038fa1bd497ecee699e011b54ea828";
export const API_SECRET_C =
  "7b0ed760f7f6f2ca19bd614de057191d1b80069d8d9222b496cfc1051f90860f";
