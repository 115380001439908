import React from "react";
import { useTheme } from "../../Context";
import Button from "../Button/Button";
import Hoverable from "../Hoverable/Hoverable";
import Tag from "./../Tag/Tag";
import "./Keywords.css";

const Keywords = (props) => {
  const { theme } = useTheme();
  const handleAddKeyword = (e) => {
    if (e.which === 13) {
      props.handleAddTag();
    }
  };

  const mapTags = () => {
    let mappedTags = props.tags.map((tag, index) => (
      <div className='query-params__tag-wrapper' key={`tag--${index}`}>
        <Tag
          value={tag}
          editTag={() => props.removeTag(index)}
          removeTag={() => props.removeTag(index)}
        />
      </div>
    ));
    return mappedTags;
  };

  return (
    <div className='query-params'>
      <label className='query-params__label' htmlFor='keyword-input'>
        Keywords
      </label>
      <div className={`query-params__input-wrapper --${theme.name}`}>
        <div className='input-wrapper'>
          <input
            className={`query-params__input --${theme.name}`}
            value={props.inputValue}
            onChange={props.handleInputValueChange}
            onKeyDown={handleAddKeyword}
            type='text'
            name='keyword-input'
          />
          <div className='input-wrapper__buttons'>
            <Hoverable
              restingStyle={theme.button.primary}
              hoveredStyle={theme.hoveredButton.primary}
              render={(style) => (
                <Button
                  onClick={props.handleAddTag}
                  style={style}
                  label='Add'
                />
              )}
            />
            <Hoverable
              restingStyle={theme.button.secondary}
              hoveredStyle={theme.hoveredButton.secondary}
              render={(style) => (
                <Button onClick={props.clearTags} style={style} label='Clear' />
              )}
            />
          </div>
        </div>
        <div className='query-params__keywords'>{mapTags()}</div>
      </div>
    </div>
  );
};

export default Keywords;
