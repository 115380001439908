import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import React, { FC, useEffect, useReducer } from "react";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Wrapper from "./components/Wrapper/Wrapper";
import { ThemeContext, useTheme } from "./Context";
import { themes } from "./styles";
import ToTopButton from "./ToTopButton/ToTopButton";

library.add(fal, fad, fab, fas, far);

const ThemeProvider: FC<{ children: any }> = (props) => {
  const [theme, set] = useReducer((state: any, next: any) => {
    if (next) {
      window.localStorage.setItem("theme", next);
      state = (themes as any)[next];
      return state;
    }
    switch (state) {
      case themes.light:
        window.localStorage.setItem("theme", "dark");
        state = themes.dark;
        return state;
      case themes.dark:
        window.localStorage.setItem("theme", "light");
        state = themes.light;
        return state;
      default:
        return state;
    }
  }, themes.light);

  useEffect(() => {
    const mode = window.localStorage.getItem("theme");
    if (mode) {
      set(mode);
    } else {
      set("light");
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, set }}>
      {props.children}
    </ThemeContext.Provider>
  );
};

const Inner = () => {
  const { theme } = useTheme();
  return (
    <div className={`App`} style={theme.base}>
      <Header />
      <Wrapper />
      <ToTopButton />
      <Footer />
    </div>
  );
};

const App = () => {
  return (
    <ThemeProvider>
      <Inner />
    </ThemeProvider>
  );
};

export default App;
