import PropTypes from "prop-types";
import React from "react";
import "./Button.css";

const Button = React.forwardRef((props, ref) => {
  return (
    <button
      ref={ref}
      className={`button`}
      style={props.style}
      onClick={props.onClick}
    >
      {props.label}
    </button>
  );
});

Button.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.any,
};

export default Button;
