import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Hoverable.css";
import * as styles from "./../../styles";

const Hoverable = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
    props.onHoverStart && props.onHoverStart();
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    props.onHoverEnd && props.onHoverEnd();
  };

  const shared = {
    transition: styles.transition.default,
    cursor: "pointer",
  };

  const hoverable = {
    ...shared,
    ...props.restingStyle,
  };

  const hovered = {
    ...shared,
    ...props.hoveredStyle,
  };

  const style = isHovered ? hovered : hoverable;

  return (
    <div
      onMouseOver={handleHover}
      onMouseLeave={handleMouseLeave}
      className={`hoverable ${props.className}`}
      style={shared}
    >
      {props.render(style)}
    </div>
  );
};

Hoverable.defaultProps = {
  className: "default",
};

Hoverable.propTypes = {
  hoveredStyle: PropTypes.any,
  onHoverEnd: PropTypes.func,
  onHoverStart: PropTypes.func,
  render: PropTypes.func,
  restingStyle: PropTypes.any,
  className: PropTypes.string,
};

export default Hoverable;
