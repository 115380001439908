import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTheme } from "../../Context";
import "./Tag.css";

const Tag = (props) => {
  const { theme } = useTheme();

  return (
    <span style={theme.base} className='tag' onClick={props.editTag}>
      <p className='tag__tag-text'>{props.value}</p>
      <span className='tag__tag-x' onClick={props.removeTag}>
        <FontAwesomeIcon icon={["far", "times"]} />
      </span>
    </span>
  );
};

export default Tag;
