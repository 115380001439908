import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "../../Context";
import * as photoAPI from "../../util/api";
import Button from "../Button/Button";
import Hoverable from "../Hoverable/Hoverable";
import Controls from "./../Controls/Controls";
import Gallery from "./../Gallery/Gallery";
import "./Wrapper.css";

const Wrapper = () => {
  const { theme } = useTheme();

  const initPhotoState = {
    colA: [],
    colB: [],
    colC: [],
  };
  const [photos, setPhotos] = useState({ ...initPhotoState });
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [colHeights, setColHeights] = useState([0, 0, 0]);

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddTag = async () => {
    let tag = inputValue;
    let curTags = [...tags, tag];
    if (inputValue.trim() === "") {
      return;
    }
    setTags([...tags, tag]);
    setPhotos(() => initPhotoState);
    setInputValue("");
    await getImages(curTags);
  };

  const handleRemoveTag = async (index) => {
    let tagSet = tags.slice();
    tagSet.splice(index, 1);
    setTags([...tagSet]);
    setPhotos({ ...initPhotoState });
    await getImages(tagSet);
  };

  const getImages = async (curTags) => {
    setLoading(true);
    let colA = [];
    let colB = [];
    let colC = [];
    let a = 0;
    let b = 1;
    let c = 2;

    let heights = [...colHeights];

    let colArray = [
      {
        col: a,
        array: [...colA],
      },
      {
        col: b,
        array: [...colB],
      },
      {
        col: c,
        array: [...colC],
      },
    ];
    function addToShortestColumn(photoObj) {
      let min = Math.min(...heights);
      let idx = heights.indexOf(min);
      heights[idx] += photoObj.height / photoObj.width / window.innerWidth;
      colArray[idx].array.push(photoObj);
    }
    let currentTags = curTags ? curTags : tags || [];
    try {
      const fetched = await photoAPI.fetchPhotos(30, currentTags);

      await fetched.forEach((image) => {
        let imgObj = {
          url: image.urls.regular,
          id: image.id,
          downloadLink: image.urls.full,
          downloadLocation: image.links.download_location,
          color: image.color,
          height: image.height,
          width: image.width,
          user: {
            url: image.user.links.html,
            name: image.user.username,
          },
        };
        if (window.innerWidth > 600) {
          addToShortestColumn(imgObj);
        } else {
          colArray[0].array.push(imgObj);
        }
      });

      setPhotos((p) => {
        return {
          colA: [...p.colA, ...colArray[a].array],
          colB: [...p.colB, ...colArray[b].array],
          colC: [...p.colC, ...colArray[c].array],
        };
      });

      setColHeights(heights);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getImages();
  }, []);

  const handleLoadMore = () => {
    let height = window.pageYOffset;
    async function loadThenScroll() {
      await getImages();
      let scrollOptions = {
        top: height,
        left: 0,
      };
      window.scrollTo(scrollOptions);
    }
    loadThenScroll();
  };

  const buttonRef = useRef(null);

  const handleLazyLoad = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      const scrollPos = window.pageYOffset;
      const screenSize = window.innerHeight;
      const offHeight = scrollPos + screenSize;
      const docHeight = document.documentElement.offsetHeight;
      if (offHeight >= docHeight - 200) {
        await getImages();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleLazyLoad);
    return () => {
      window.removeEventListener("scroll", handleLazyLoad);
    };
  });

  const handleClearTags = () => {
    setInputValue("");
    setTags([]);
    getImages();
  };

  const clearAndGetImages = async (tagSet = []) => {
    setPhotos(initPhotoState);
    await getImages(tagSet);
  };

  return (
    <div className={`wrapper --${theme.name}`}>
      <Controls
        theme={theme}
        tags={tags}
        handleAddTag={handleAddTag}
        removeTag={handleRemoveTag}
        inputValue={inputValue}
        handleInputChange={handleInputChange}
        resetAndGet={() => clearAndGetImages()}
        clearTags={handleClearTags}
      />
      <div className='refresh-wrapper'>
        <Hoverable
          restingStyle={theme.button.secondary}
          hoveredStyle={theme.hoveredButton.secondary}
          className='button--refresh'
          render={(style) => (
            <FontAwesomeIcon
              icon={["far", "sync"]}
              onClick={() => clearAndGetImages(tags)}
              color={style.color}
            />
          )}
        />
      </div>

      <Gallery photos={photos} theme={theme} />

      {loading && <Spin size='large' delay={200} />}
      <Button
        ref={buttonRef}
        label='Load More'
        style={theme.button.secondary}
        onClick={handleLoadMore}
      />
    </div>
  );
};

export default Wrapper;
