import React, { useContext } from "react";
import { themes } from "./styles";

export const ThemeContext = React.createContext({
  theme: themes.light,
  set: (_) => {},
});

export const useTheme = () => {
  const { theme, set } = useContext(ThemeContext);
  return { theme, set };
};

export const ImageDataContext = React.createContext(null);
