// api info kept in separate secure repo
import {
  ACCESS_KEY_A,
  API_SECRET_A,
  ACCESS_KEY_B,
  API_SECRET_B,
  ACCESS_KEY_C,
  API_SECRET_C,
} from "./config";

const keySets = {
  A: {
    key: ACCESS_KEY_A,
    secret: API_SECRET_A,
  },
  B: {
    key: ACCESS_KEY_B,
    secret: API_SECRET_B,
  },
  C: {
    key: ACCESS_KEY_C,
    secret: API_SECRET_C,
  },
};

let useKey = "A";

const updateKey = () => {
  const key = window.localStorage.getItem("useKey");
  if (key) {
    switch (key) {
      case "A":
        useKey = "B";
        window.localStorage.setItem("useKey", "B");
        break;
      case "B":
        useKey = "C";
        window.localStorage.setItem("useKey", "C");
        break;
      case "C":
        useKey = "A";
        window.localStorage.setItem("useKey", "A");
        break;
      default:
        break;
    }
  } else {
    useKey = "B";
    window.localStorage.setItem("useKey", "B");
  }
};

// pulls photos from unsplash api based on dynamic query params
export const fetchPhotos = async (limit, tags, retryCount) => {
  // sets headers
  const headers = {
    Authorization: `Client-ID ${keySets[useKey].key}`,
    secret: keySets[useKey].secret,
  };

  // sets method
  const init = {
    method: "GET",
    headers: headers,
  };

  // if no param is generated in-app, defaults to 5 to avoid failure
  let countLimit = limit || 30;

  // if specific keyword is specified in-app, it is included in query
  // otherwise query runs with no keyword param

  let newRequest;

  if (tags.length > 0) {
    let query = tags.join(" ");
    newRequest = `https://api.unsplash.com/photos/random?query=${query}&count=${countLimit}&tclient_secret=${keySets[useKey].key}`;
  } else {
    newRequest = `https://api.unsplash.com/photos/random?count=${countLimit}&tclient_secret=${keySets[useKey].secret}`;
  }

  // actual api request
  try {
    const response = await fetch(newRequest, init);
    const responseBody = await response.json();
    return responseBody;
  } catch (error) {
    console.error(`fetch error: ${error.message}`);
    updateKey();
    if ((retryCount || 0) < 3) {
      return fetchPhotos(limit, tags, (retryCount || 0) + 1);
    }
  }
};

// 'forces' a downlaod across clients
const forceDownload = (blob, filename) => {
  let a = document.createElement("a");
  a.download = filename;
  a.href = blob;
  a.click();
};

const apiDownload = async (downloadLocation) => {
  const headers = {
    Authorization: `Client-ID ${keySets[useKey].key}`,
    secret: keySets[useKey].secret,
  };

  // sets method
  const init = {
    method: "GET",
    headers: headers,
  };
  try {
    return fetch(downloadLocation, init);
  } catch (e) {
    console.error(e);
    updateKey();
  }
};

// Current blob size limit is around 500MB for browsers
export const downloadResource = async (
  url,
  filename,
  location,
  downloadLocation
) => {
  if (!filename)
    filename = url
      .split("\\")
      .pop()
      .split("/")
      .pop();
  fetch(url, {
    headers: new Headers({
      Origin: location,
    }),
    mode: "cors",
  })
    // blob is a funny word
    .then((response) => response.blob())
    .then((blob) => {
      let blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, filename);
    })
    .then(() => {
      apiDownload(downloadLocation);
    })
    .catch((e) => console.error(e));
};
