// colors
export const primary = {
  base: "#7d7bff",
  light: "#c9c3ff",
  dark: "#6256b9",
};

export const secondary = {
  base: "#ac3d50",
  light: "#d8a2ab",
  dark: "#6e0d1d",
};

export const gray = {
  white: "#fff",
  black: "#222",
  offWhite: "#fdfeff",
};

export const layout = {
  flex: {
    column: {
      display: "flex",
      flexDirection: "column",
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    js: {
      justifyContent: "flex-start",
    },
    as: {
      alignItems: "flex-start",
    },
  },
};

export const transition = {
  default: ".2s ease-in-out",
};

export const button = {
  primary: {
    color: gray.white,
    background: primary.base,
    borderColor: primary.base,
  },
  secondary: {
    color: primary.base,
    background: "transparent",
    borderColor: primary.base,
    cursor: "pointer",
  },
};

export const hoveredButton = {
  secondary: {
    ...button.secondary,
    background: primary.light,
    color: primary.dark,
  },
  primary: {
    ...button.primary,
    background: primary.dark,
  },
};

export const themes = {
  light: {
    name: "light",
    base: {
      color: gray.black,
      background: gray.offWhite,
    },
    button: { ...button },
    hoveredButton: { ...hoveredButton },
  },
  dark: {
    name: "dark",
    base: {
      color: gray.white,
      background: gray.black,
    },
    button: {
      ...button,
      secondary: {
        color: primary.light,
        background: "transparent",
      },
    },
    hoveredButton: {
      ...hoveredButton,
      secondary: {
        color: primary.light,
        background: `${primary.base}33`,
      },
      primary: {
        background: `${primary.base}aa`,
        color: gray.offWhite,
        borderColor: primary.base,
      },
    },
  },
};
