import React from "react";
import { useTheme } from "../../Context";
import Keywords from "./../Keywords/Keywords";
import "./Controls.css";

const Controls = (props) => {
  const { theme } = useTheme();
  return (
    <div className={`controls controls--${theme.name}`}>
      <Keywords
        handleAddTag={props.handleAddTag}
        tags={props.tags}
        removeTag={props.removeTag}
        handleInputValueChange={props.handleInputChange}
        inputValue={props.inputValue}
        clearTags={props.clearTags}
      />
    </div>
  );
};

export default Controls;
