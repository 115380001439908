import React from "react";
import { useTheme } from "../../Context";
import Toggle from "../Toggle/Toggle";
import "./Header.css";

const Header = () => {
  const { theme } = useTheme();
  return (
    <header className={`header`} style={theme.base}>
      <div className='header__header-inner'>
        <h1
          className='header__header-title'
          style={{ color: theme.base.color }}
        >
          Random Image Generator
        </h1>
        <Toggle />
      </div>
    </header>
  );
};

export default Header;
