import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTheme } from "../Context";
import "./ToTopButton.css";

const ToTopButton = () => {
  const { theme } = useTheme();
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleReveal);
    return () => {
      window.removeEventListener("scroll", handleReveal);
    };
  });

  const handleReveal = () => {
    let scroll = window.pageYOffset;
    let trigger = 1000;
    if (scroll > trigger) {
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
  };

  const toTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return shouldShow ? (
    <button className='to-top-button' onClick={toTop}>
      <div className='to-top-button__inner' style={theme.button.primary}>
        <p className='to-top-button__inner__text'>Back to Top</p>
        <FontAwesomeIcon
          icon='arrow-up'
          className='icon to-top-button__inner__icon'
          color='white'
        />
      </div>
    </button>
  ) : null;
};

export default ToTopButton;
