import React, { useRef, Suspense, lazy } from "react";
import { useTheme } from "../../Context";
// import Image from './../Image/Image';
import "./Gallery.css";

const LazyImage = lazy(() => import("./../Image/Image"));

const Fallback = () => {
  return (
    <div
      style={{
        height: Math.random() * 360,
        width: "100%",
        minHeight: 120,
        minWidth: 120,
        margin: 4,
        backgroundColor: getBGColor(),
        transition: ".3s ease",
      }}
    ></div>
  );
};

const getBGColor = () => {
  let randomColor = Math.floor(Math.random() * 16777215).toString(16);
  let RG = randomColor.slice(0, 4);
  let forceBlueish = RG.concat("ff");
  return `#${forceBlueish}77`;
};

const Gallery = (props) => {
  const { theme } = useTheme();
  const galleryRef = useRef(null);

  const mapColumn = (col, colKey) => {
    let photoSet = col.map((image, index) => (
      <Suspense
        key={`${colKey}-${image.id}-${index}`}
        fallback={<Fallback color={image.color} />}
      >
        <LazyImage
          imageSource={image.url}
          altTag='randomly generated from Unsplash.com'
          id={image.id}
          downloadLink={image.downloadLink}
          downloadLocation={image.downloadLocation}
          key={`img-${image.id}`}
          user={{
            url: image.user.url,
            name: image.user.name,
          }}
        />
      </Suspense>
    ));
    return photoSet;
  };

  return (
    <div className={`gallery gallery---${theme}`}>
      <div id='gallery' className='gallery__wrapping-column' ref={galleryRef}>
        <div
          id='colA'
          className='gallery__image-column gallery__image-column--left'
        >
          {mapColumn(props.photos.colA, "a")}
        </div>
        <div
          id='colB'
          className='gallery__image-column gallery__image-column--center'
        >
          {mapColumn(props.photos.colB, "b")}
        </div>
        <div
          id='colC'
          className='gallery__image-column gallery__image-column--right'
        >
          {mapColumn(props.photos.colC, "c")}
        </div>
      </div>
    </div>
  );
};
export default Gallery;
