import React from "react";
import PropTypes from "prop-types";
import "./Toggle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "../../Context";

const Toggle = (props) => {
  const { theme, set } = useTheme();
  const checked = theme.name === "light";
  return (
    <div className={`toggle-wrapper --${theme.name}`} onClick={() => set()}>
      <input
        type='checkbox'
        className='toggle__input'
        checked={checked}
        onChange={() => set()}
      />
      <div className='toggle' is-on={`${checked}`}>
        <span className='toggle__half --off' is-active={`${!checked}`}>
          {props.useLabels && (
            <p className='toggle-label'>{props.labels.off}</p>
          )}

          {props.useIcons && (
            <div className='toggle__button' toggle-on={`${checked}`} />
          )}

          {props.useIcons && (
            <FontAwesomeIcon icon={props.icons.off} className='icon' />
          )}
        </span>
        <span className='toggle__half --on' is-active={`${checked}`}>
          {props.useLabels && <p className='toggle-label'>{props.labels.on}</p>}
          {props.useIcons && (
            <FontAwesomeIcon icon={props.icons.on} className='icon' />
          )}
        </span>
      </div>
    </div>
  );
};

Toggle.defaultProps = {
  useLabels: false,
  useIcons: true,
  icons: {
    off: "moon",
    on: "sun",
  },
};

Toggle.propTypes = {
  checked: PropTypes.any,
  icons: PropTypes.shape({
    off: PropTypes.any,
    on: PropTypes.any,
  }),
  labels: PropTypes.shape({
    off: PropTypes.any,
    on: PropTypes.any,
  }),
  onChange: PropTypes.any,
  useIcons: PropTypes.any,
  useLabels: PropTypes.any,
};

export default Toggle;
